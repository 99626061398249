import './App.css';
import React, { useEffect } from 'react';
import { ReactComponent as Logo } from './logoAn.svg';
import { gsap } from "gsap";


function App() { 


  useEffect(() => {

    setTimeout(() => {

      gsap.to(".App-header", { background: '#f0efef' , duration:3}).then(() => {
        gsap.to(".address", { opacity: 1 }).then(()=>{
          gsap.from("#t2", {transform: "translate(-107px, 0px)",  ease: "power4.out" })
          gsap.to("#t2", { opacity: 1 })
          gsap.to("#t1", { opacity: 1 })
          gsap.from("#t1", {transform: "translate(0px, -30px)" }) 
          gsap.to(".address", {color:'#4C4846', marginTop:'-5em' , ease: "elastic.out(1, 0.3)"}).then(() => {
            gsap.to("#LIcon", 
            { attr: { 
                 d: "M39.03,0c-8.19,11.51-13.19,24.6-15,38.05c-1.29-5.77-4.19-11.25-8.69-15.71C10.95,18,5.61,15.2,0,13.93 C13.71,12.58,27.16,7.92,39.03,0z"
                 }, 
              ease: "power4.out",
              duration: 2,
              delay: 0.2
            }).then(()=>{
              gsap.to('.logo-w', {scale: 1.1, duration: 2, delay: 0.2, ease: "elastic.out(1, 0.3)"}).then(() => {
                gsap.to('.logo-w', {scale: 1, duration: 2})
              })
            })        

          })
          
        })
      }) 

    }, 2000)
   
  }, []) 

  return (
    <div className="App">
      <header className="App-header">
        <Logo className='logo-w' />
      
        <p className='subTitle'>
         Antre Consultech LTD.
        </p> 

       <p className='address'>
        
        Maslak Mah. Maslak Meydan Sk. Beybi Giz
Plaza A Blok No: 1 Ic kapi No: <b>99</b> Kat- 26-27<br/>
Maslak/Istanbul<br/>
34485
       </p>
        
      </header>
    </div>
  );
}

export default App;
